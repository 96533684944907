export default {
    vacancies: {
        list: '/api/vacancies/me/',
        specs: '/api/vacancies/me/specs/',
        facets: '/api/vacancies/me/facets/',
        info: '/api/vacancies/me/info_block/',
        promo: '/api/vacancies/info_module/',
        id: id => `/api/vacancies/me/${id}/`,
        duplicate: id => `/api/vacancies/me/${id}/duplicate/`,
        setContact: '/api/vacancies/me/contact/',
        changeContact: id => `/api/vacancies/me/contact/${id}/`,
        create: '/api/vacancies/me/',
        completionProgress: id => `/api/vacancies/me/${id}/completion_progress/`,
        statuses: '/api/vacancies/archive_statuses/',
        archive: id => `/api/vacancies/me/${id}/archive/`,
        delete: id => `/api/vacancies/me/${id}/delete/`,

        tariffs: {
            get: '/api/subscriptions/tariffs/?purpose=vacancy',
            post: id => `/api/vacancies/me/${id}/subscription/`,
            delete: id => `/api/vacancies/me/${id}/subscription/cancel/`,
            changePaymentMethod: id => `/api/vacancies/me/${id}/subscription/change-payment-method/`,
        },
    },
};
