export default {
    resumes: {
        list: '/api/resumes/me/',
        for_request: '/api/resumes/me/for_request/',
        specs: '/api/resumes/me/specs/',
        facets: '/api/resumes/me/facets/',
        promo: '/api/resumes/info_module/',
        id: id => `/api/resumes/me/${id}/`,
        duplicate: id => `/api/resumes/me/${id}/duplicate/`,
        setAbout: id => `/api/resumes/me/${id}/about/`,
        setPersonal: id => `/api/resumes/me/${id}/personal/`,
        setPosition: id => `/api/resumes/me/${id}/position/`,
        create: '/api/resumes/me/',
        completionProgress: id => `/api/resumes/me/${id}/completion_progress/`,
        statuses: '/api/resumes/archive_statuses/',
        archive: id => `/api/resumes/me/${id}/archive/`,
        delete: id => `/api/resumes/me/${id}/delete/`,
        submit: id => `/api/resumes/me/${id}/submit/`,
        setNoExperience: id => `/api/resumes/me/${id}/set_no_experience/`,
        offers: '/api/resumes/offers/',
        photo: id => `/api/resumes/me/${id}/photo/`,

        experience: {
            post: '/api/resumes/experience/',
            put: id => `/api/resumes/experience/${id}/`,
            patch: id => `/api/resumes/experience/${id}/`,
            delete: id => `/api/resumes/experience/${id}/`,
        },

        education: {
            post: '/api/resumes/education/',
            put: id => `/api/resumes/education/${id}/`,
            patch: id => `/api/resumes/education/${id}/`,
            delete: id => `/api/resumes/education/${id}/`,
        },

        additionalEducation: {
            post: '/api/resumes/additional_education/',
            put: id => `/api/resumes/additional_education/${id}/`,
            patch: id => `/api/resumes/additional_education/${id}/`,
            delete: id => `/api/resumes/additional_education/${id}/`,
        },

        tariffs: {
            get: '/api/subscriptions/tariffs/?purpose=resume',
            post: id => `/api/resumes/me/${id}/subscription/`,
            delete: id => `/api/resumes/me/${id}/subscription/cancel/`,
            changePaymentMethod: id => `/api/resumes/me/${id}/subscription/change-payment-method/`,
        },
    },
};
