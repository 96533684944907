export default {
    job: {
        vacancy: {
            list: '/api/vacancies/',
            specs: '/api/vacancies/specs/',
            facets: '/api/vacancies/facets/',
            id: id => `/api/vacancies/${id}/`,
            request: '/api/request/vacancy/',
            similar: id => `/api/vacancies/${id}/similar/`,
            top: '/api/vacancies/top/',
        },

        resume: {
            list: '/api/resumes/',
            specs: '/api/resumes/specs/',
            facets: '/api/resumes/facets/',
            id: id => `/api/resumes/${id}/`,
            similar: id => `/api/resumes/${id}/similar/`,
            top: '/api/resumes/top/',
        },
    },
};
